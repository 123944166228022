import React from "react"

import '../styles/bubbles.css';
const Bubbles = () => {
    return(
        <React.Fragment>
        <div className="bubbles">
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
        </div>
        </React.Fragment>
    );
}

export default Bubbles;