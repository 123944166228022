import React, { useState, useEffect } from 'react';
import '../styles/skillssection.css';
import db from "../scripts/firebase";
import StarRatings from 'react-star-ratings';
import { motion } from "framer-motion"

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August",
                    "September", "October", "November", "December"];

const SkillsSection = () => {
    const [myLanguageSkills, setMyLanguageSkills] = useState({});
    const [myFrameworkSkills, setMyFrameworkSkills] = useState({});
    const [myTestingSkills, setMyTestingSkills] = useState({});
    const [myCloudSkills, setMyCloudSkills] = useState({});
    const [myCicdSkills, setMyCicdSkills] = useState({});
    const [myDatabaseSkills, setMyDatabaseSkills] = useState({});
    const [myToolsSkills, setMyToolsSkills] = useState({});
    const [myMiscSkills, setMyMiscSkills] = useState({});
    const [myCertificates, setMyCertificates] = useState({});

    const fetchSkills = async () => {
        // Fetch languages
        var temp = [];
        await db.collection("skills").orderBy("skill_level", "desc").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                temp.push((doc.data()));
                setMyLanguageSkills({
                    data: temp
                });
            });
        });

        // Fetch frameworks
        temp = [];
        await db.collection("skills").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                if(doc.data().type == "framework") {
                    temp.push((doc.data()));
                    setMyFrameworkSkills({
                        data: temp
                    });
                }
            });
        });

        // Fetch testing
        temp = [];
        await db.collection("skills").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                if(doc.data().type == "testing") {
                    temp.push((doc.data()));
                    setMyTestingSkills({
                        data: temp
                    });
                }
            });
        });

        // Fetch cloud
        temp = [];
        await db.collection("skills").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                if(doc.data().type == "cloud") {
                    temp.push((doc.data()));
                    setMyCloudSkills({
                        data: temp
                    });
                }
            });
        });

        // Fetch ci/cd
        temp = [];
        await db.collection("skills").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                if(doc.data().type == "ci/cd") {
                    temp.push((doc.data()));
                    setMyCicdSkills({
                        data: temp
                    });
                }
            });
        });

        // Fetch database
        temp = [];
        await db.collection("skills").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                if(doc.data().type == "database") {
                    temp.push((doc.data()));
                    setMyDatabaseSkills({
                        data: temp
                    });
                }
            });
        });

        // Fetch tools
        temp = [];
        await db.collection("skills").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                if(doc.data().type == "tools") {
                    temp.push((doc.data()));
                    setMyToolsSkills({
                        data: temp
                    });
                }
            });
        });

        // Fetch miscellaneous
        temp = [];
        await db.collection("skills").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                if(doc.data().type == "misc") {
                    temp.push((doc.data()));
                    setMyMiscSkills({
                        data: temp
                    });
                }
            });
        });
    };

    const fetchCertificates = async () => {
        var temp = [];
        await db.collection("certificates").orderBy("date", "desc").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                temp.push((doc.data()));
                setMyCertificates({
                    data: temp
                });
            });
        });
    }

    const getMyLanguageSkillRows = () => {
        if (myLanguageSkills.data == null) {
            return
        }

        var myLanguageSkillsRows = []
        myLanguageSkills.data.forEach(
            (d) => {
                // Get years of experience
                var currDate = Date.now();
                var expYears = (currDate / 1000 - d.start_date.seconds) / (60 * 60 * 24 * 365);
                expYears = Math.round(expYears * 10) / 10; // round to 1 decimal

                myLanguageSkillsRows.push(
                    <tr className='skills-row'>
                        <motion.td whileHover={{scale: 1.1}} className='skills-language'>
                            <a className='skills-clickable' href={d.url} target="_blank">{d.name}</a>
                        </motion.td>
                        <td className='skills-level'><StarRatings
                                                        rating={d.skill_level}
                                                        starRatedColor="#fefcbf"
                                                        starEmptyColor='#ffffff4d'
                                                        numberOfStars={5}
                                                        name='skills-star-rating'
                                                        starDimension="25px"
                                                        starSpacing="0"
                                                        svgIconPath="M 23.976562 3.9785156 A 1.50015 1.50015 0 0 0 22.5 5.5 L 22.5 8.5 A 1.50015 1.50015 0 1 0 25.5 8.5 L 25.5 5.5 A 1.50015 1.50015 0 0 0 23.976562 3.9785156 z M 10.902344 9.4042969 A 1.50015 1.50015 0 0 0 9.8574219 11.980469 L 11.978516 14.101562 A 1.5012202 1.5012202 0 0 0 14.101562 11.978516 L 11.980469 9.8574219 A 1.50015 1.50015 0 0 0 10.902344 9.4042969 z M 37.050781 9.4042969 A 1.50015 1.50015 0 0 0 36.019531 9.8574219 L 33.898438 11.978516 A 1.5012202 1.5012202 0 0 0 36.021484 14.101562 L 38.142578 11.980469 A 1.50015 1.50015 0 0 0 37.050781 9.4042969 z M 24 13 A 11 11 0 0 0 24 35 A 11 11 0 0 0 24 13 z M 5.5 22.5 A 1.50015 1.50015 0 1 0 5.5 25.5 L 8.5 25.5 A 1.50015 1.50015 0 1 0 8.5 22.5 L 5.5 22.5 z M 39.5 22.5 A 1.50015 1.50015 0 1 0 39.5 25.5 L 42.5 25.5 A 1.50015 1.50015 0 1 0 42.5 22.5 L 39.5 22.5 z M 13.009766 33.445312 A 1.50015 1.50015 0 0 0 11.978516 33.898438 L 9.8574219 36.019531 A 1.501221 1.501221 0 1 0 11.980469 38.142578 L 14.101562 36.021484 A 1.50015 1.50015 0 0 0 13.009766 33.445312 z M 34.943359 33.445312 A 1.50015 1.50015 0 0 0 33.898438 36.021484 L 36.019531 38.142578 A 1.5012209 1.5012209 0 1 0 38.142578 36.019531 L 36.021484 33.898438 A 1.50015 1.50015 0 0 0 34.943359 33.445312 z M 23.976562 37.978516 A 1.50015 1.50015 0 0 0 22.5 39.5 L 22.5 42.5 A 1.50015 1.50015 0 1 0 25.5 42.5 L 25.5 39.5 A 1.50015 1.50015 0 0 0 23.976562 37.978516 z"
                                                    />
                        </td>
                        <td className='skills-exp'>{expYears}{" yrs"}</td>
                    </tr>
                )
            }
        )

        return myLanguageSkillsRows;
    }

    const getMyCertificateRows = () => {
        if (myCertificates.data == null) {
            return
        }

        var myCertificateRows = []
        myCertificates.data.forEach(
            (d) => {
                // Get date of completion
                const completionDate = (new Date(d.date.seconds * 1000))
                const stringCompletionDate = `${monthNames[completionDate.getMonth()]} ${completionDate.getFullYear()}`

                myCertificateRows.push(
                    <tr className='skills-row'>
                        <td className='skills-certificate'>{d.name}</td>
                        <motion.td whileHover={{scale: 1.1}} className='skills-language'>
                            <a className='skills-clickable' href={d.url} target="_blank">
                                {d.source}
                            </a>
                        </motion.td>
                        <td className='skills-exp'>{stringCompletionDate}</td>
                    </tr>
                )
            }
        )

        return myCertificateRows;
    }

    const getGroupSkills = (type) => {
        var mySkills = []


        if (type == "testing") {
            if (myTestingSkills.data == null) {
                return
            }
            myTestingSkills.data.forEach(
                (d) => {
                    mySkills.push(
                        <motion.a
                            initial={{ y: 200, opacity: 0}}
                            whileInView={{ y: 0, opacity: 1 }}
                            viewport={{ once: true }}
                            whileHover={{y: -10}}
                            className='skills-clickable group-skill-clickable'
                            href={d.url}
                            target='_blank'>
                                {d.name}
                        </motion.a>
                    );
                }
            )
        }
        else if (type == "framework") {
            if (myFrameworkSkills.data == null) {
                return
            }
            myFrameworkSkills.data.forEach(
                (d) => {
                    mySkills.push(
                        <motion.a
                            initial={{ y: 300, opacity: 0}}
                            whileInView={{ y: 0, opacity: 1 }}
                            whileHover={{y: -10}}
                            viewport={{ once: true }}
                            className='skills-clickable group-skill-clickable'
                            href={d.url}
                            target='_blank'>
                                {d.name}
                        </motion.a>
                    );
                }
            )
        }
        else if (type == "cloud") {
            if (myCloudSkills.data == null) {
                return
            }
            myCloudSkills.data.forEach(
                (d) => {
                    mySkills.push(
                        <motion.a
                            initial={{ y: 300, opacity: 0}}
                            whileInView={{ y: 0, opacity: 1 }}
                            viewport={{ once: true }}
                            whileHover={{y: -10}}
                            className='skills-clickable group-skill-clickable'
                            href={d.url}
                            target='_blank'>
                                {d.name}
                        </motion.a>
                    );
                }
            )
        }
        else if (type == "ci/cd") {
            if (myCicdSkills.data == null) {
                return
            }
            myCicdSkills.data.forEach(
                (d) => {
                    mySkills.push(
                        <motion.a
                            initial={{ y: 300, opacity: 0}}
                            whileInView={{ y: 0, opacity: 1 }}
                            viewport={{ once: true }}
                            whileHover={{y: -10}}
                            className='skills-clickable group-skill-clickable'
                            href={d.url}
                            target='_blank'>
                                {d.name}
                        </motion.a>
                    );
                }
            )
        }
        else if (type == "database") {
            if (myDatabaseSkills.data == null) {
                return
            }
            myDatabaseSkills.data.forEach(
                (d) => {
                    mySkills.push(
                        <motion.a
                            initial={{ y: 300, opacity: 0}}
                            whileInView={{ y: 0, opacity: 1 }}
                            viewport={{ once: true }}
                            whileHover={{y: -10}}
                            className='skills-clickable group-skill-clickable'
                            href={d.url}
                            target='_blank'>
                                {d.name}
                        </motion.a>
                    );
                }
            )
        }
        else if (type == "tools") {
            if (myToolsSkills.data == null) {
                return
            }
            myToolsSkills.data.forEach(
                (d) => {
                    mySkills.push(
                        <motion.a
                            initial={{ y: 300, opacity: 0}}
                            whileInView={{ y: 0, opacity: 1 }}
                            viewport={{ once: true }}
                            whileHover={{y: -10}}
                            className='skills-clickable group-skill-clickable'
                            href={d.url}
                            target='_blank'>
                                {d.name}
                        </motion.a>
                    );
                }
            )
        }
        else if (type == "misc") {
            if (myMiscSkills.data == null) {
                return
            }
            myMiscSkills.data.forEach(
                (d) => {
                    mySkills.push(
                        <motion.a
                            initial={{ y: 300, opacity: 0}}
                            whileInView={{ y: 0, opacity: 1 }}
                            viewport={{ once: true }}
                            whileHover={{y: -10}}
                            className='skills-clickable group-skill-clickable'
                            href={d.url}
                            target='_blank'>
                                {d.name}
                        </motion.a>
                    );
                }
            )
        }
        return mySkills;
    }

    useEffect(() => {
        fetchSkills();
        fetchCertificates();
    }, []);

    return(
        <React.Fragment>
            <div id="skills">
                <div className="navbar-spacer"><p></p></div>
                <motion.h1
                    className='section-title'
                    initial={{ x: -200, opacity: 0}}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{duration: 0.9}}>
                    Skills
                </motion.h1>
                <motion.h2
                    className='sub-title'
                    initial={{ x: -200, opacity: 0}}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{duration: 1.8}}>
                    Languages
                </motion.h2>
                <div className='sub-title-spacer'></div>
                <div className='sub-title-spacer'></div>
                <motion.table
                    initial={{ opacity: 0}}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{duration: 2}}
                    className='skills-table'>
                    <tr className="header-row">
                        <th className="header-title">Language</th>
                        <th className="header-title">Skill</th>
                        <th className="header-title">Experience</th>
                    </tr>
                    {getMyLanguageSkillRows()}
                </motion.table>

                <div className='sub-title-spacer'></div>
                <div className='sub-title-spacer'></div>
                <motion.h2
                    className='sub-title'
                    initial={{ x: -200, opacity: 0}}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{duration: 1.8}}>
                    Certificates
                </motion.h2>
                <div className='sub-title-spacer'></div>
                <div className='sub-title-spacer'></div>
                <motion.table
                    initial={{ opacity: 0}}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{duration: 2}}
                    className='skills-table'>
                    <tr className="header-row">
                        <th className="header-title">Certificate</th>
                        <th className="header-title">Source</th>
                        <th className="header-title">Completion</th>
                    </tr>
                    {getMyCertificateRows()}
                </motion.table>
                <div className='sub-title-spacer'></div>
                <div className='sub-title-spacer'></div>

                <motion.div className="group-skills-section">
                <motion.h2
                    className='sub-title'
                    initial={{ x: -200, opacity: 0}}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{duration: 1.8}}>
                    Testing
                </motion.h2>
                <div className='group-skills'>
                    {getGroupSkills("testing")}
                </div>
                <div className='sub-title-spacer'></div>

                <motion.h2
                    className='sub-title'
                    initial={{ x: -200, opacity: 0}}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{duration: 1.8}}>
                    Frameworks
                </motion.h2>
                <div className='group-skills'>
                    {getGroupSkills("framework")}
                </div>
                <div className='sub-title-spacer'></div>

                <motion.h2
                    className='sub-title'
                    initial={{ x: -200, opacity: 0}}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{duration: 1.8}}>
                    Cloud
                </motion.h2>
                <div className='group-skills'>
                    {getGroupSkills("cloud")}
                </div>
                <div className='sub-title-spacer'></div>

                <motion.h2
                    className='sub-title'
                    initial={{ x: -200, opacity: 0}}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{duration: 1.8}}>
                    CI/CD
                </motion.h2>
                <div className='group-skills'>
                    {getGroupSkills("ci/cd")}
                </div>
                <div className='sub-title-spacer'></div>

                <motion.h2
                    className='sub-title'
                    initial={{ x: -200, opacity: 0}}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{duration: 1.8}}>
                    Database
                </motion.h2>
                <div className='group-skills'>
                    {getGroupSkills("database")}
                </div>
                <div className='sub-title-spacer'></div>

                <motion.h2
                    className='sub-title'
                    initial={{ x: -200, opacity: 0}}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{duration: 1.8}}>
                    Tools
                </motion.h2>
                <div className='group-skills'>
                    {getGroupSkills("tools")}
                </div>
                <div className='sub-title-spacer'></div>

                <motion.h2
                    className='sub-title'
                    initial={{ x: -200, opacity: 0}}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{duration: 1.8}}>
                    Miscellaneous
                </motion.h2>
                <div className='group-skills'>
                    {getGroupSkills("misc")}
                </div>
                </motion.div>

                <div className='sub-title-spacer'></div>
            </div>
        </React.Fragment>
    );
}

export default SkillsSection;