
import Header from './components/header'
import TopNavBar from './components/topnavbar';
import AboutSection from './components/AboutSection';
import ExperienceSection from './components/ExperienceSection';
import SkillsSection from './components/SkillsSection';
import ProjectsSection from './components/ProjectsSection';
import ContactSection from './components/ContactSection';
import Bubbles from "./components/Bubbles";
import React from "react";
import { useRef, useState } from 'react';

import './styles/App.css';

function App() {
  const [useBubbles, setUseBubbles] = useState(false);

  const addBubbles = () => {
    if (window.scrollY >= 650)
    {
      setUseBubbles(true);
    }
    else
    {
      setUseBubbles(false);
    }
  }

  // Add event listeners
  window.addEventListener("scroll", addBubbles);

  return (
    <React.Fragment>
      <TopNavBar />
      <Header />
      <AboutSection />
      <ExperienceSection />
      <SkillsSection />
      <ProjectsSection />
      <ContactSection />
      {useBubbles ? <Bubbles/> : <></>}
    </React.Fragment>
  );
}

export default App;
