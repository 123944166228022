import React, { useState, useEffect } from 'react';
import '../styles/experiencesection.css';
import db from "../scripts/firebase";
import { motion } from "framer-motion"

import ResumeFile from '../img/DarylDang.pdf'

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August",
                    "September", "October", "November", "December"];

const ExperienceSection = () => {
    const [resumeDownloaded, setResumeDownloaded] = useState(false);
    const [myExperiences, setMyExperiences] = useState({});
    const [myOtherExperiences, setMyOtherExperiences] = useState({});

    const fetchExperiences = async () => {
        var temp = [];

        await db.collection("experiences").orderBy("start_date", "desc").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                temp.push((doc.data()));
                setMyExperiences({
                    data: temp
                });
            });
        })
    };

    const fetchOtherExperiences = async () => {
        var temp = [];

        await db.collection("other_experiences").orderBy("end_date", "desc").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                temp.push((doc.data()));
                setMyOtherExperiences({
                    data: temp
                });
            });
        })
    };

    const getExperienceCarouselItems = () => {
        if (myExperiences.data == null) {
            return
        }

        var experienceItems = [];
        // console.log(myExperiences.data);
        myExperiences.data.forEach(
            (d) => {
                // Convert EPOCH to UTC Date for start and end dates
                const startDate = (new Date(d.start_date.seconds * 1000))
                const stringStartDate = `${monthNames[startDate.getMonth()]} ${startDate.getFullYear()}`
                const endDate = (new Date(d.end_date.seconds * 1000))
                var stringEndDate = ''
                var responsibilities = []
                const techStackMap = new Map(Object.entries(d.tech_stack)); // turn tech stack into map (from object)
                var techStack = []

                if (endDate.getFullYear() < startDate.getFullYear()) {
                    stringEndDate = "Present";
                    experienceItems.splice(0, 0,
                        <motion.div
                            whileTap={{ scale: 1.05 }}
                            whileHover={{ scale: 1.05 }}
                            initial={{ y: 100, opacity: 0}}
                            whileInView={{ y: 0, opacity: 1 }}
                            viewport={{ once: true }}
                            transition={{duration: 0.1}}
                            className="experience-item">
                            <img className='experience-logo' src={d.logo_url} alt="" />
                            <p className='experience-company'>{d.company}</p>
                            <p className='experience-role'>{d.role}</p>
                            <p className='experience-type'>({d.type})</p>
                            <p className='experience-location'>{d.location}</p>
                            <p className='experience-date'>{stringStartDate} - {stringEndDate}</p>

                            {d.responsibilities.forEach(
                                    (r) => {
                                        responsibilities.push(
                                            <li>
                                                {r}
                                            </li>
                                        );
                                    }
                            )}
                            <ul className='experience-responsibilities'>
                                {responsibilities}
                            </ul>
    
                            {techStackMap.forEach(
                                    (link, name) => {
                                        techStack.push(
                                            <motion.a
                                                whileHover={{y: -10}}
                                                className='experience-tech'
                                                href={link}
                                                target='_blank'>
                                                {name}
                                            </motion.a>
                                        )
                                    }
                            )}
                            <div className='experience-techstack-blocks'>
                                {techStack}
                            </div>
                        </motion.div>
                    );
                }
                else{
                    stringEndDate = `${monthNames[endDate.getMonth()]} ${endDate.getFullYear()}`
                    experienceItems.push(
                        <motion.div
                            whileTap={{ scale: 1.05 }}
                            whileHover={{ scale: 1.05 }}
                            initial={{ y: 100, opacity: 0}}
                            whileInView={{ y: 0, opacity: 1 }}
                            viewport={{ once: true }}
                            transition={{duration: 0.1}}
                            className="experience-item">
                            <img className='experience-logo' src={d.logo_url} alt="" />
                            <p className='experience-company'>{d.company}</p>
                            <p className='experience-role'>{d.role}</p>
                            <p className='experience-type'>({d.type})</p>
                            <p className='experience-location'>{d.location}</p>
                            <p className='experience-date'>{stringStartDate} - {stringEndDate}</p>
    
                            {d.responsibilities.forEach(
                                    (r) => {
                                        responsibilities.push(
                                            <li>
                                                {r}
                                            </li>
                                        );
                                    }
                            )}
                            <ul className='experience-responsibilities'>
                                {responsibilities}
                            </ul>
    
                            {techStackMap.forEach(
                                    (link, name) => {
                                        techStack.push(
                                            <motion.a
                                                whileHover={{y: -10}}
                                                className='experience-tech'
                                                href={link}
                                                target='_blank'>
                                                {name}
                                            </motion.a>
                                        )
                                    }
                            )}
                            <div className='experience-techstack-blocks'>
                                {techStack}
                            </div>
                        </motion.div>
                    );
                }
            }
        );
        return experienceItems;
    };

    const getOtherExperienceCarouselItems = () => {
        if (myOtherExperiences.data == null) {
            return
        }

        var otherExperienceItems = [];
        myOtherExperiences.data.forEach(
            (d) => {
                const startDate = (new Date(d.start_date.seconds * 1000))
                const stringStartDate = `${monthNames[startDate.getMonth()]} ${startDate.getFullYear()}`
                const endDate = (new Date(d.end_date.seconds * 1000))
                var stringEndDate = ''
                if (endDate.getFullYear() < startDate.getFullYear()) {
                    stringEndDate = "Present";
                }
                else{
                    stringEndDate = `${monthNames[endDate.getMonth()]} ${endDate.getFullYear()}`
                }

                otherExperienceItems.push(
                    <motion.div
                        whileTap={{ scale: 1.05 }}
                        whileHover={{ scale: 1.05 }}
                        initial={{ y: 100, opacity: 0}}
                        whileInView={{ y: 0, opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{duration: 0.1}}
                        className="experience-item">
                        <p className='experience-company'>{d.name}</p>
                        <p className='experience-role'>{d.role}</p>
                        <p className='experience-date'>{stringStartDate} - {stringEndDate}</p>
                        <p className='experience-desription'>{d.description}</p>
                    </motion.div>
                );
            }
        );
        return otherExperienceItems;
    };

    useEffect(() => {
        fetchExperiences();
        fetchOtherExperiences();
    }, []);

    return (
        <React.Fragment>
            <div id='experience'>
                <div className="navbar-spacer"><p></p></div>
                <motion.h1
                    className='section-title'
                    initial={{ x: -200, opacity: 0}}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{duration: 0.9}}>
                    Experience
                </motion.h1>
                {resumeDownloaded?
                <div className='download-button'>
                    <a className='downloaded-text'>✔️</a>
                </div>
                :
                <motion.div
                className='download-button'
                onClick={() => setResumeDownloaded(true)}
                initial={{ opacity: 0}}
                whileInView={{ opacity: 1 }}
                transition={{type:"spring", duration: 1}}
                viewport={{ once: true }}
                whileHover={{scale: 1.2}}>
                    <a className='download-text' href={ResumeFile} download="DarylDangResume">
                        Resume
                    </a>
                </motion.div>
                }
                <motion.h2
                    className='sub-title'
                    initial={{ x: -200, opacity: 0}}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{duration: 1.8}}>
                    Professional Work Experiences
                </motion.h2>
                <div className='experience-container'>{getExperienceCarouselItems()}</div>
                <div className="section-spacer"></div>
                <motion.h2
                    className='sub-title'
                    initial={{ x: -200, opacity: 0}}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{duration: 1.8}}>
                    Other Experiences
                </motion.h2>
                <div className='experience-container'>{getOtherExperienceCarouselItems()}</div>
                <div className="section-spacer"></div>
            </div>
        </React.Fragment>
    );

}
export default ExperienceSection;