import React from "react"
import '../styles/aboutsection.css';
import { motion } from "framer-motion"
import AboutImg from '../img/aboutimg.jpg'

const AboutSection = () => {

    return(
        <React.Fragment>
            <div id="about">
                <div className="navbar-spacer"><p></p></div>
                <motion.img src={AboutImg}
                     alt="..."
                     className="about-portrait"
                     initial={{ y: 250, opacity: 0}}
                     whileInView={{ y: 0, opacity: 1 }}
                     viewport={{ once: true }}
                     transition={{duration: 0.5}}
                />
                <motion.div
                    whileTap={{ scale: 1.1 }}
                    whileHover={{ scale: 1.1}}
                    initial={{ y: 250, opacity: 0}}
                    whileInView={{ y: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ease:"linear", duration: 0.}}
                    className="about-description-box">
                    <p className="about-description">
                    I am a passionate Software Engineer who has recently graduated with an undergraduate
                    degree in Software Engineering at the University of Calgary. I originally started
                    in Business but after taking an intro Computer Science elective I decided to switch!
                    Ever since then, software, programming, and coding have become my new passion
                    and I am proud to be in an industry that I love to work in.
                    Some areas of interest include SQ testing, architecture/design, web development,
                    and machine learning.
                    One of my hobbies involve mentoring youth in programming, computer science, and
                    engineering.
                    I'm always looking for new opportunities that are both challenging and new!
                    </p>
                </motion.div>
                <div className="section-spacer"></div>
            </div>
        </React.Fragment>
    );
}

export default AboutSection;