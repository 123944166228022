import React, {useRef, useState} from "react"
import Wave from 'react-wavify'
import '../styles/contactsection.css';
import 'react-notifications/lib/notifications.css';
import emailjs from '@emailjs/browser';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { motion } from "framer-motion"
import GithubLogo from '../img/githublink.png'
import LinkedInLogo from '../img/linkedinlink.png'
import EmailLogo from '../img/maillink.png'

const ContactSection = () => {
    const form = useRef();
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [message, setMessage] = useState("");

    const sendEmail = (e) => {
        e.preventDefault();
        NotificationManager.success(`Talk to you soon ${userName}`, 'Message Sent!');
        setUserName("");
        setUserEmail("");
        setMessage("");
        emailjs.sendForm('service_2jlu9m5', 'template_gzss75t', form.current, 'TXhxWBs3iH2XOXiqJ')
          .then((result) => {
          }, (error) => {
          })
    };

    return(
        <React.Fragment>
            <div id="contact">
                <div className="navbar-spacer"><p></p></div>
                <motion.h1
                    initial={{ x: -200, opacity: 0}}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{duration: 0.9}}
                    className='section-title'>
                        Contact
                </motion.h1>
                <motion.p
                    className='contact-message'
                    initial={{ opacity: 0}}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{duration: 1.8}}>
                    Feel free to send me a message!
                </motion.p>
                <form ref={form} className="contact-form" onSubmit={sendEmail}>
                    <div className="input-row">
                        <motion.input
                        initial={{ x: -200, opacity: 0}}
                        whileInView={{ x: 0, opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{duration: 0.9}}
                        className="input-box"
                        type="text"
                        placeholder="Your name"
                        name="user_name"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        required
                        />
                    </div>
                    <div className="input-row">
                        <motion.input
                        initial={{ x: -200, opacity: 0}}
                        whileInView={{ x: 0, opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{duration: 1.1}}
                        className="input-box"
                        type="email"
                        placeholder="Your Email"
                        name="user_email"
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)}
                        required
                        />
                    </div>
                    <div className="input-row">
                        <motion.textarea
                        initial={{ x: -200, opacity: 0}}
                        whileInView={{ x: 0, opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{duration: 1.3}}
                        className="input-text-area"
                        placeholder="Message"
                        name="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                        />
                    </div>
                    <div className="input-row">
                        <motion.button
                        initial={{ y: 100, opacity: 0}}
                        whileInView={{ y: 0, opacity: 1 }}
                        whileHover={{scale:1.3}}
                        viewport={{ once: true }}
                        className="contact-submit-button"
                        type="submit"
                        >
                        SEND
                        </motion.button>
                    </div>
                    <NotificationContainer/>
                </form>
                <div className="navbar-spacer"><p></p></div>
                <Wave className="wave" fill='#fefcbf' paused={false}
                    options={{
                    height: 20,
                    amplitude: 20,
                    speed: 0.20,
                    points: 3
                    }}
                />
                <div className="contact-links">
                    <motion.a
                        initial={{ y: 50, opacity: 0}}
                        whileInView={{ y: 0, opacity: 1 }}
                        whileHover={{scale:1.3}}
                        viewport={{ once: true }}
                        title="github.com/dellod"
                        className="contact-link"
                        href="https://github.com/dellod"
                        target="_blank">
                        <img className="contact-link-logo" src={GithubLogo} />
                    </motion.a>
                    <motion.a
                        initial={{ y: 50, opacity: 0}}
                        whileInView={{ y: 0, opacity: 1 }}
                        whileHover={{scale:1.3}}
                        viewport={{ once: true }}
                        title="linkedin.com/in/darylvdang/"
                        className="contact-link"
                        href="https://www.linkedin.com/in/darylvdang/"
                        target="_blank">
                        <img className="contact-link-logo" src={LinkedInLogo} />
                    </motion.a>
                    <motion.a
                        initial={{ y: 50, opacity: 0}}
                        whileInView={{ y: 0, opacity: 1 }}
                        whileHover={{scale:1.3}}
                        viewport={{ once: true }}
                        title="darylvdang@gmail.com"
                        className="contact-link"
                        href="mailto:darylvdang@gmail.com"
                        target="_blank">
                        <img className="contact-link-logo" src={EmailLogo} />
                    </motion.a>
                </div>
                <div className="acknowledgement-section">
                    <p className="acknowledgement-text">Icons by <a href="https://icons8.com/">Icons8</a></p>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ContactSection;