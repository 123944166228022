import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDn3Mo2rmewSWS2nE7k9SGlPI3rH7uJdxM",
    authDomain: "personal-website-d76db.firebaseapp.com",
    databaseURL: "https://personal-website-d76db-default-rtdb.firebaseio.com",
    projectId: "personal-website-d76db",
    storageBucket: "personal-website-d76db.appspot.com",
    messagingSenderId: "445356861",
    appId: "1:445356861:web:d7eca555f23722820d8354",
    measurementId: "G-QYZHR1TMYS"
};


// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export default db;