import React, {useState, useEffect} from "react"

import db from "../scripts/firebase";
import '../styles/projectssection.css';
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';
import { motion } from "framer-motion"

import RegularFileImg from '../img/regular_file.png'
import GitHubFileImg from '../img/github_logo.png'
import WebsiteFileImg from '../img/www_logo.png'
import NotionFileImg from '../img/notion_logo.png'
import DriveFileImg from '../img/drive_logo.png'
import DemoFileImg from '../img/demo_logo.png'

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August",
                    "September", "October", "November", "December"];

const ProjectsSection = () => {
    const [myProjects, setMyProjects] = useState({});
    const [showProjectCard, setShowProjectCard] = useState(false);
    const [currProject, setCurrProject] = useState({});
    const [currProjectCompletionDate, setCurrProjectCompletionDate] = useState("");

    const fetchMyProjects = async () => {
        // Fetch projects
        var temp = [];
        await db.collection("projects").orderBy("date", "desc").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                temp.push((doc.data()));
                setMyProjects({
                    data: temp
                });
            });
        });
    }

    const handleShow = (d) => {
        const compDate = (new Date(d.date.seconds * 1000))
        const stringCompDate = `${monthNames[compDate.getMonth()]} ${compDate.getFullYear()}`
        setCurrProjectCompletionDate(stringCompDate)
        setCurrProject(d);
        setShowProjectCard(true);
    }

    const handleClose = () => setShowProjectCard(false);

    const getProjectCards = () => {
        if (myProjects.data == null) {
            return
        }

        var projectCards = []
        myProjects.data.forEach(
            (d) => {
                // Get date of completion
                const completionDate = (new Date(d.date.seconds * 1000))
                const stringCompletionDate = `${monthNames[completionDate.getMonth()]} ${completionDate.getFullYear()}`

                projectCards.push(
                    <motion.div
                        whileTap={{ scale: 1.05 }}
                        whileHover={{ scale: 1.05 }}
                        initial={{ y: 100, opacity: 0}}
                        whileInView={{ y: 0, opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{duration: 0.1}}
                        className="project-card"
                        onClick={() => handleShow(d)}>
                        <h2 className="project-name">{d.name}</h2>
                        <h3 className="project-subtitle">{d.subtitle}</h3>
                        <p className="project-date">{stringCompletionDate}</p>
                        <img className="project-mainpreview-image" src={d.images[0]} alt="" />
                    </motion.div>
                )
            }
        )
        return projectCards;
    }

    const createCarouselImages = (images) => {
        if (images == null) {
            return
        }

        var carouselImages = []
        // Carousel style
        const carouselItemStyle = {
            height: '150%',
        };
        images.forEach(
            (i) => {
                carouselImages.push(
                    <Carousel.Item style={carouselItemStyle}>
                        <img className="carousel-img"src={i} />
                    </Carousel.Item>
                )
            }
        )
        return carouselImages;
    }

    const getCurrentProjectLinks = (links) => {
        if (links == null) {
            return
        }
        const linkMap = new Map(Object.entries(links));
        var linkElements = [];
        {linkMap.forEach(
            (link, name) => {
                var fileLogo = RegularFileImg;
                if (name.toLowerCase() == "github") {
                    fileLogo = GitHubFileImg;
                }
                else if (name.toLowerCase() == "website") {
                    fileLogo = WebsiteFileImg;
                }
                else if (name.toLowerCase() == "notion") {
                    fileLogo = NotionFileImg;
                }
                else if (name.toLowerCase() == "course") {
                    fileLogo = DriveFileImg;
                }
                else if (name.toLocaleLowerCase() == "demo") {
                    fileLogo = DemoFileImg;
                }
                linkElements.push(
                    <a className="link-name" href={link} target="_blank"><img className="link-element-logo" src={fileLogo} alt="" />{name}</a>
                )
            }
        )}
        return linkElements;
    }

    const getTechStackBlocks = (techStack) => {
        if (techStack == null) {
            return
        }

        console.log(techStack);
        const techStackMap = new Map(Object.entries(techStack));
        var techStackBlocks = [];

        {techStackMap.forEach(
            (link, name) => {
                techStackBlocks.push(
                    <motion.a whileHover={{y: -10}} className='project-tech' href={link} target="_blank">
                        {name}
                    </motion.a>
                )
            }
        )}
        return techStackBlocks;
    }

    useEffect(() => {
        fetchMyProjects();
    }, []);

    return(
        <React.Fragment>
            <div id="projects">
                <div className="navbar-spacer"><p></p></div>
                <motion.h1
                    initial={{ x: -200, opacity: 0}}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{duration: 0.9}}
                    className='section-title'>
                        Projects
                </motion.h1>
                <div className='project-cards'>
                    {getProjectCards()}
                </div>
                <Modal show={showProjectCard} centered={true} onHide={handleClose}>
                    <Modal.Body className="project-modal-card">
                        <h1 className="project-modal-title">{currProject.name}</h1>
                        <h2 className="project-modal-subtitle">{currProject.subtitle}</h2>
                        <h2 className="project-modal-date">{currProjectCompletionDate}</h2>
                        <Carousel style={{height: "20em"}} data-bs-theme="dark" controls={false} interval={5000} className="project-carousel">
                            {createCarouselImages(currProject.images)}
                        </Carousel>
                        <p className="project-modal-description">{currProject.description}</p>
                        <div className="project-links">
                            {getCurrentProjectLinks(currProject.links)}
                        </div>
                        <div className="project-tech-stack-blocks">
                            {getTechStackBlocks(currProject.tech_stack)}
                        </div>
                        <motion.p whileHover={{scale: 1.02}}className="project-modal-close-button" onClick={() => handleClose()}>Close</motion.p>
                    </Modal.Body>
                </Modal>
                <div className="navbar-spacer"><p></p></div>
                <div className="navbar-spacer"><p></p></div>
                <div className="navbar-spacer"><p></p></div>
                <div className="navbar-spacer"><p></p></div>
                <div className="navbar-spacer"><p></p></div>
            </div>
        </React.Fragment>
    );
}

export default ProjectsSection;