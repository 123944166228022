import { FaTimes } from 'react-icons/fa';
import { useRef, useState} from 'react';
import React from "react";
import { motion } from "framer-motion"

import '../styles/topnavbar.css';
import bars from '../img/water-waves.png'
import sun from '../img/sunset.png'

const TopNavBar = () => {
    const navRef = useRef();
    const barsRef = useRef();
    const [navbarScroll, setNavbarScroll] = useState(false);

    // Mobile bar button
    const showTopNavBar = () => {
        // Removes the class name from the reference
        navRef.current.classList.toggle("responsive-nav-bar");

        // Hides the bars
        barsRef.current.classList.toggle("hidden");
    }

    const changeNavbarBackground = () => {
        // Toggle the navbar background when passed 300 pixels and this is on desktop display
        if(window.scrollY >= 100 && window.innerWidth >= 768)
        {
            setNavbarScroll(true);
        }
        else
        {
            setNavbarScroll(false);
        }
    }

    // Add event listeners
    window.addEventListener("scroll", changeNavbarBackground);

    return (
        <div>
            <nav className={navbarScroll? "top-navbar active-top-navbar" : "top-navbar"} ref={navRef}>
                <motion.a whileHover={{scale:1.3}} href="#" onClick={showTopNavBar}><img className="navbar-logo" src={sun} alt="LOGO HERE" /></motion.a>
                <motion.a whileHover={{y: 15}} href="#about" className='navbar-item' onClick={showTopNavBar}>About</motion.a>
                <motion.a whileHover={{y: 15}} href="#experience" className='navbar-item' onClick={showTopNavBar}>Experience</motion.a>
                <motion.a whileHover={{y: 15}} href="#skills" className='navbar-item' onClick={showTopNavBar}>Skills</motion.a>
                <motion.a whileHover={{y: 15}} href="#projects" className='navbar-item' onClick={showTopNavBar}>Projects</motion.a>
                <motion.a whileHover={{y: 15}} href="#contact" className='navbar-item' onClick={showTopNavBar}>Contact</motion.a>
                <button className="nav-btn nav-close-btn" onClick={showTopNavBar}>
                    <FaTimes/>
                </button>
            </nav>
            <img className="nav-btn bars-icon" ref={barsRef} src={bars} alt="" onClick={showTopNavBar} />
        </div>
    );
}

export default TopNavBar;
