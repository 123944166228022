import React from "react"
import { MDBBtn } from 'mdb-react-ui-kit';
import Wave from 'react-wavify'
import TextTransition, { presets } from 'react-text-transition';
import '../styles/header.css';

const ROLES = ['software engineer', 'software test engineer', 'web developer', 'automation engineer', 'learner'];

const Header = () => {

    const scrollToMainContent = (element, delay) => {
        setTimeout(() => {
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, delay);
    }

    const goToContent = () => {
        setIsContentButtonPushed(true);
        const element = document.getElementById('about');
        scrollToMainContent(element, 1000);
    }

    const checkIfContentButtonPushed = () => {
        return isContentButtonPushed ?
            setContentButtonClass("arrow-button-pressed") : setContentButtonClass("arrow-button");

    }

    const [index, setIndex] = React.useState(0);
    const [isContentButtonPushed, setIsContentButtonPushed] = React.useState(false);
    const [contentButtonClass, setContentButtonClass] = React.useState("arrow-button")

    React.useEffect(() => {
        // Check if main content button is pushed
        checkIfContentButtonPushed()

        // Change subtitle every 3 seconds
        const intervalId = setInterval(
        () => setIndex((index) => index + 1),
        2000, // every 2 seconds
        );
        return () => clearTimeout(intervalId);
    }, [isContentButtonPushed, contentButtonClass]);

    return (
    <div className="header">
        <header>
            <h1 className="name-title">DARYL DANG</h1>
            <h2 className="role-subtitle">
                a passionate <TextTransition inline={true} direction="down"springConfig={presets.gentle}>{ROLES[index % ROLES.length]}</TextTransition>
            </h2>
        </header>
        <Wave className="wave" fill='#2a6fdb' paused={false}
            options={{
            height: 20,
            amplitude: 20,
            speed: 0.20,
            points: 3
            }}
        />
        <div className="bottom-header">
            <MDBBtn className={contentButtonClass} onClick={goToContent} rippleDuration={1000} rippleRadius={80} rippleColor='#122c91' color="tertiary" rippleUnbound rippleCentered size="lg">
            </MDBBtn>
        </div>
    </div>
    );
}

export default Header;